<template>
  <v-dialog
    width="700"
    persistent
    transition="slide-x-transition"
    v-model="get_modal_view_endereco"
  >
    <v-card class="expande-horizontal wrap">
      <div class="expande-horizontal px-1 pr-3 py-3">
        <v-btn dark @click="fecha_modal_view_endereco" icon>
          <v-icon color="grey">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span style="font-size: 17pt; width: 100%;" class="fonte">
          Registro de endereco
        </span>
        <div class="expande-horizontal"></div>
        <v-btn class="elevation-3" icon @click="fecha_modal_view_endereco">
          <v-icon color="red">
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <div class="expande-horizontal wrap py-6 pb-0">
        <v-flex xs12>
          <v-form ref="form">
            <v-flex class="px-7" xs12>
              <div class="expande-horizontal">
                <v-text-field
                  v-model="get_endereco.postal_code"
                  autofocus
                  @keyup.enter="get_cep(get_endereco.postal_code)"
                  dense
                  label="CEP"
                ></v-text-field>
                <v-btn
                  text
                  :color="$theme.primary"
                  small
                  @click="get_cep(get_endereco.postal_code)"
                  >Buscar CEP</v-btn
                >
              </div>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Endereço
              </span>
              <v-text-field
                v-model="get_endereco.address"
                dense
                solo
                label="Endereço"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Bairro
              </span>
              <v-text-field
                v-model="get_endereco.neigthborhood"
                dense
                solo
                label="Bairro"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Cidade
              </span>
              <v-text-field
                v-model="get_endereco.city"
                dense
                solo
                label="Cidade"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Estado
              </span>
              <v-text-field
                v-model="get_endereco.uf"
                dense
                solo
                label="Estado"
              ></v-text-field>
            </v-flex>

            <v-flex class="px-7" xs12>
              <span class="py-3 fonte orange--text">
                Número da casa
              </span>
              <v-text-field
                v-model="get_endereco.house_number"
                :rules="[v => !!v || 'Preencha o número da casa']"
                dense
                solo
                label="Número da casa"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-flex>
      </div>

      <div class="expande-horizontal">
        <v-btn large dark @click="valida_form" tile block color="green">
          <span class="fonte"> Salvar </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_endereco", "get_modal_view_endereco", "getLoggedUser"])
  },
  methods: {
    ...mapActions([
      "get_cep",
      "criar_endereco",
      "atualizar_endereco",
      "fecha_modal_view_endereco"
    ]),
    valida_form() {
      if (this.$refs.form.validate()) {
        this.get_endereco._id
          ? this.atualizar_endereco()
          : this.criar_endereco();
      }
    }
  }
};
</script>
